import {BranchName, BranchViewModel, IncidentAccessLevel, IncidentReportViewModel} from './apis/api.client';
import {
  Report
} from '../incident-response/components/report/view-reports/reports-table-results/reports-table-results.component';

export class AppConstants {
  public static readonly LastUrlKeyName = 'jchnLastUrl';
  public static readonly SupportEmailAddress = 'jbrown@aspennetworkinc.org';
  public static AssessmentKeySessionId = 'assessment-result-key';
}

export const stateFormlyFieldOptions = [
  {value: 'Alabama', label: 'Alabama'},
  {value: 'Alaska', label: 'Alaska'},
  {value: 'Arizona', label: 'Arizona'},
  {value: 'Arkansas', label: 'Arkansas'},
  {value: 'California', label: 'California'},
  {value: 'Colorado', label: 'Colorado'},
  {value: 'Connecticut', label: 'Connecticut'},
  {value: 'Delaware', label: 'Delaware'},
  {value: 'District of Columbia', label: 'District of Columbia'},
  {value: 'Florida', label: 'Florida'},
  {value: 'Georgia', label: 'Georgia'},
  {value: 'Hawaii', label: 'Hawaii'},
  {value: 'Idaho', label: 'Idaho'},
  {value: 'Illinois', label: 'Illinois'},
  {value: 'Indiana', label: 'Indiana'},
  {value: 'Iowa', label: 'Iowa'},
  {value: 'Kansas', label: 'Kansas'},
  {value: 'Kentucky', label: 'Kentucky'},
  {value: 'Louisiana', label: 'Louisiana'},
  {value: 'Maine', label: 'Maine'},
  {value: 'Maryland', label: 'Maryland'},
  {value: 'Massachusetts', label: 'Massachusetts'},
  {value: 'Michigan', label: 'Michigan'},
  {value: 'Mississippi', label: 'Mississippi'},
  {value: 'Missouri', label: 'Missouri'},
  {value: 'Montana', label: 'Montana'},
  {value: 'Nebraska', label: 'Nebraska'},
  {value: 'Nevada', label: 'Nevada'},
  {value: 'New Hampshire', label: 'New Hampshire'},
  {value: 'New Jersey', label: 'New Jersey'},
  {value: 'New Mexico', label: 'New Mexico'},
  {value: 'New York', label: 'New York'},
  {value: 'North Carolina', label: 'North Carolina'},
  {value: 'North Dakota', label: 'North Dakota'},
  {value: 'Ohio', label: 'Ohio'},
  {value: 'Oklahoma', label: 'Oklahoma'},
  {value: 'Oregon', label: 'Oregon'},
  {value: 'Pennsylvania', label: 'Pennsylvania'},
  {value: 'Rhoda Island', label: 'Rhode Island'},
  {value: 'South Carolina', label: 'South Carolina'},
  {value: 'South Dakota', label: 'South Dakota'},
  {value: 'Tennessee', label: 'Tennessee'},
  {value: 'Texas', label: 'Texas'},
  {value: 'Utah', label: 'Utah'},
  {value: 'Vermont', label: 'Vermont'},
  {value: 'Virginia', label: 'Virginia'},
  {value: 'Washington', label: 'Washington'},
  {value: 'West Virginia', label: 'West Virginia'},
  {value: 'Wisconsin', label: 'Wisconsin'},
  {value: 'Wyoming', label: 'Wyoming'},
];

export const Branches: BranchViewModel[] = [
  BranchViewModel.fromJS({
    id: '62b4c7f143fd34a6f90d7c6b',
    name: BranchName.Schools,
  }),
  BranchViewModel.fromJS({
    id: '62b4c7f143fd34a6f90d7c6c',
    name: BranchName.FirstResponders,
  }),
  BranchViewModel.fromJS({
    id: '636a4b9e985a36277e961297',
    name: BranchName.HealthcareWorkers,
  }),
];

export const BranchNameToBranchMap = new Map<BranchName, BranchViewModel>([
  [
    BranchName.Schools,
    Branches.find((branch) => branch.name === BranchName.Schools),
  ],
  [
    BranchName.FirstResponders,
    Branches.find((branch) => branch.name === BranchName.FirstResponders),
  ],
  [
    BranchName.HealthcareWorkers,
    Branches.find((branch) => branch.name === BranchName.HealthcareWorkers),
  ],
]);

export enum TourEvents {
  Close = 'close',
  Cancel = 'cancel',
  Complete = 'complete',
}

export enum AssessmentNames {
  GeneralLevel2 = 'Level 2 General Screening'
}

export const getAccessIcon = (report: IncidentReportViewModel | Report) => {
  if (!report) {
    return '';
  }
  return report?.accessLevel === IncidentAccessLevel.AdminOnly ? 'admin_panel_settings' : 'people_alt';
};

export const getAccessLabel = (report: IncidentReportViewModel | Report) => {
  if (!report) {
    return '';
  }
  return report?.accessLevel === IncidentAccessLevel.AdminOnly ? 'Admin Only' : 'All Users';
};

export class IdentityClaims {
  public static readonly WaiverUploaded = 'waiver_uploaded';
  public static readonly ProfileCompleted = 'profile_completed';
  public static readonly TermsAndConditions = 'terms_and_conditions';
}

export class TermsAndConditions {
  public static readonly link =
    'https://static1.squarespace.com/static/57f035cc9f745646c52342b9/t/636962ad57291136776144ef/1667850925816/JCHD-ASPEN+Terms+%26+Conditions.pdf';
}
