import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, Injector, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {MonitoringErrorHandler} from './shared/services/monitoring-error-handler';
import {ApiBaseUrl} from './shared/apis/api.client';
import {ENV_CONFIG, EnvConfig} from '../configuration';
import {KeyPrefix} from './shared/abstracts/storage-base';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSidenavModule} from '@angular/material/sidenav';
import {FaConfig, FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {
  faFacebookSquare,
  faInstagramSquare,
  faTwitterSquare,
  faYoutubeSquare,
} from '@fortawesome/free-brands-svg-icons';
import {MatListModule} from '@angular/material/list';
import {AuthModule} from './auth/auth.module';
import {DatePipe} from '@angular/common';
import {MatTableModule} from '@angular/material/table';
import {BranchInterceptorClass} from './auth/branch.interceptor';
import {MatMenuModule} from '@angular/material/menu';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {AuthService} from './auth/services/auth.service';
import {initializeKeycloak} from './auth/keycloak.init';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    KeycloakAngularModule,
    MatSidenavModule,
    FontAwesomeModule,
    MatListModule,
    AuthModule,
    MatTableModule,
    MatMenuModule,
    MatToolbarModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatTooltipModule,
    FormsModule,
  ],
  providers: [
    {provide: ErrorHandler, useClass: MonitoringErrorHandler},
    {
      provide: ApiBaseUrl,
      useFactory: (envConfig: EnvConfig) => envConfig.appConfig.apiBaseUrl,
      deps: [ENV_CONFIG],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, Injector, AuthService],
    },
    KeycloakService,
    {
      provide: KeyPrefix,
      useFactory: (envConfig: EnvConfig) => envConfig.appConfig.storageKeyPrefix,
      deps: [ENV_CONFIG],
    },
    BranchInterceptorClass,
    DatePipe,
    MatSnackBar,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary, faConfig: FaConfig) {
    library.addIcons(faTwitterSquare, faFacebookSquare, faInstagramSquare, faYoutubeSquare);
    faConfig.defaultPrefix = 'fab';
  }
}
