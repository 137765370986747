import {Injectable, Injector} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {Disposable} from '../../shared/extensions/disposable';
import {StateSubject} from '../../shared/extensions/state-subject';
import {KeycloakEventType, KeycloakService} from 'keycloak-angular';
import {SessionStorageService} from '../../shared/services/session-storage.service';
import {
  BranchClient,
  LinkPlaceholderToUserRequest,
  ProfileType,
  PublicClient,
  UserClient
} from '../../shared/apis/api.client';
import {AppConstants} from '../../shared/constants';
import {AuthUser} from '../models/auth-user.model';
import {RoleName} from '../../shared/enums/role';
import {Router} from '@angular/router';
import {fromPromise} from 'rxjs/internal-compatibility';
import swal from 'sweetalert2';
import {filter, map, take} from 'rxjs/operators';
import {KeycloakProfile} from 'keycloak-js';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends Disposable {
  public authUser$ = new StateSubject<AuthUser>(null);
  private isDoneLoadingSubject = new BehaviorSubject<boolean>(false);
  public isDoneLoading$ = this.isDoneLoadingSubject.asObservable();
  public userBranches = new BehaviorSubject<string[]>(null);
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  constructor(
    private keycloakService: KeycloakService,
    private readonly sessionStorageService: SessionStorageService,
    private readonly userClient: UserClient,
    private publicClient: PublicClient,
    private branchClient: BranchClient,
    private injector: Injector,
  ) {
    super();
  }

  public get authUser(): AuthUser {
    return this.authUser$.getValue();
  }

  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isDoneLoading$,
    this.isAuthenticated$
  ]).pipe(
    map((values) => {
      return values.every((b) => b);
    })
  );

  public async login(targetUrl?: string): Promise<void> {
    // Note: before version 9.1.0 of the library you needed to
    // call encodeURIComponent on the argument to the method.
    // We are using the session to store the last url visited if there is one use it, it is the truth.
    if (this.sessionStorageService.exists(AppConstants.LastUrlKeyName)) {
      targetUrl = this.sessionStorageService.get(AppConstants.LastUrlKeyName);
      await this.keycloakService.login({
        redirectUri: window.location.origin + targetUrl,
      });
      return;
    }
    // Check if we have any target if so use it now it is the truth.
    if (targetUrl) {
      this.sessionStorageService.set(AppConstants.LastUrlKeyName, targetUrl);
      await this.keycloakService.login({
        redirectUri: window.location.origin + targetUrl,
      });
      return;
    }
    // Whelp now we know nothing so lets just use dashboard
    await this.keycloakService.login({
      redirectUri: '',
    });
  }

  private async doLogout(): Promise<void> {
    const redirectUrl = window.location.origin + '/';
    await this.keycloakService.logout(redirectUrl);
    this.authUser$.next(null);
  }

  public logout(currentUrl: string, showConfirmationPrompt = true): Observable<void> {
    if (showConfirmationPrompt) {
      return fromPromise(
        swal
          .fire({
            title: 'Sign out',
            text: 'Are you sure you want to sign out?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: 'var(--primary-500)',
            confirmButtonText: 'Yes',
            cancelButtonText: 'Not Yet',
            buttonsStyling: true,
            reverseButtons: false,
          })
          .then((result) => {
            if (!result.value) {
              return;
            }
            return this.doLogout().then();
          })
      );
    } else {
      this.doLogout().then();
    }
  }

  public subscribeToEvents(): void {
    this.keycloakService.keycloakEvents$.subscribe({
      next: (e) => {
        if (e.type === KeycloakEventType.OnTokenExpired) {
          console.log('TOKEN EXPIRED');
          this.keycloakService.updateToken(20).then();
        }
        if (
          e.type === KeycloakEventType.OnAuthRefreshSuccess ||
          e.type === KeycloakEventType.OnAuthSuccess
        ) {
          if (!this.keycloakService.getKeycloakInstance()) {
            return;
          }
          this.keycloakService.loadUserProfile(true).then((profile) => {
            const user = AuthUser.buildAuthUserFromKcProfile(profile);
            user.roles = this.keycloakService.getUserRoles();
            const claims = this.keycloakService.getKeycloakInstance().tokenParsed;
            user.waiverUploaded = claims.waiver_uploaded;
            user.profileCompleted = claims.profile_completed;
            this.getJchnUserInfo(user).then(_ => this.isDoneLoadingSubject.next(true));
          }).catch(err => {
            this.login('');
          });
        }
        this.isAuthenticatedSubject$.next(this.keycloakService.getKeycloakInstance().authenticated);
      },
    });
  }

  public setAuthUserWaiverStatus(waiverUploaded: boolean): void {
    const user = this.authUser$.getValue();
    this.authUser$.next({ ...user, ...{ waiverUploaded } });
  }

  public setAuthUserBranch(branchId: string): void {
    this.userBranches.next([branchId]);
    const user = this.authUser$.getValue();
    this.authUser$.next({ ...user, ...{ branchIds: [branchId] } });
  }

  private async getJchnUserInfo(user: AuthUser, checkLink: boolean = false): Promise<void> {
    if (!user?.userId) {
      return;
    }

    const profile = localStorage.getItem('currentProfile');

    const results = await this.publicClient.getAuthUser(user.userId, profile ? ProfileType[profile] : null).toPromise();

    if (!results.authUser && !user?.roles.includes(RoleName.Participant)) {
      // assume participant if they are signed in, but not in the ASPEN database
      // add participant role to user so they can complete profile
      user?.roles.push(RoleName.Participant);
    }

    if (results.authUser?.profileType && results.authUser?.profileType !== profile) {
      localStorage.setItem('currentProfile', results.authUser.profileType);
    }

    if (
      !user?.roles.includes(RoleName.Participant) &&
      (!results.authUser?.branchIds || results.authUser.branchIds.length < 1)
    ) {
      const router = this.injector.get(Router);
      await router.navigate(['/auth/should-login']);
    }

    if (!results.authUser && !results.authUser?.userId && user?.roles.includes(RoleName.Participant)) {
      const request = LinkPlaceholderToUserRequest.fromJS({
        userId: user.userId,
        email: user.email,
      });

      const link = await this.publicClient.linkParticipantIfExists(request).toPromise();

      if (link.linkSuccessful && link.authUser) {
        results.authUser = link.authUser;
      }
    }

    this.authUser$.next({ ...user, ...results.authUser, firstName: user.firstName, lastName: user.lastName, inDatabase: !!results.authUser });

    const branchIds = results.authUser?.branchIds ?? ['000000000000000000000000'];
    this.userBranches.next(branchIds);
  }

  public refresh(redirectTo: string = null): void {
    this.keycloakService.updateToken(20).then();
  }

  public async refreshAsync(redirectTo: string = null): Promise<KeycloakProfile> {
    return this.keycloakService.updateToken(20).then(_ => this.keycloakService.loadUserProfile());
  }

  public async refreshAuthUser(profileCompleted: boolean, waiverUploaded: boolean): Promise<void> {
    return this.getJchnUserInfo(this.authUser, false).then(_ => {
      this.authUser.profileCompleted = profileCompleted;
      this.authUser.waiverUploaded = waiverUploaded;
    });
  }

  public authorized(roles: string[] = []): boolean {
    if (this.authUser$.getValue()) {
      return this.hasRoles(...roles);
    }
  }

  public authorized$(roles: any[]): Observable<boolean> {
    return this.authUser$.pipe(
      filter(Boolean),
      map(() => this.hasRoles(...roles)),
      take(1)
    );
  }

  public hasRole(role: string): boolean {
    const authUser = this.authUser$.getValue();
    if (!authUser || !authUser.roles || authUser.roles.length === 0) {
      return false;
    }
    return authUser.roles.some((r) => r.toLowerCase() === role.toLowerCase());
  }

  public hasAttribute(attribute: string): boolean {
    const authUser = this.authUser$.getValue();
    return !!authUser[attribute];
  }

  public hasRoles(...roles: string[]): boolean {
    const authUser = this.authUser$.getValue();
    if (!roles?.length) {
      return true;
    }

    if (!authUser?.roles?.length) {
      return false;
    }
    return roles.some((r) => authUser.roles.includes(r));
  }

}
